<template>
  <div class="dropdown inline-block relative text-sm">
    <span class="text-grey-2 opacity-50">{{ $t('contracts.sort.title') }}</span>
    <button
      class="text-grey-2 py-2 px-2 rounded inline-flex items-center"
      @click="open"
      @blur="hide"
    >
      <span class="mr-1 font-semibold">{{
        order
          ? $t('projects.orders.' + order)
          : $t('projects.orders.created_at')
      }}</span>
      <svg
        class="fill-current h-4 w-4 opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </button>
    <ul
      class="
        dropdown-menu
        absolute
        text-gray-700
        z-50
        rounded-2xl
        bg-white
        right-0
        py-3
      "
      :class="{ hidden: !show }"
    >
      <li
        class=""
        v-for="option in options"
        v-bind:key="option"
        @click="setOrder(option)"
      >
        <div
          class="
            text-sm
            py-3
            px-4
            block
            whitespace-no-wrap
            cursor-pointer
            text-left
            hover:font-semibold
            text-grey-2
          "
          :class="{ 'font-semibold': option === order }"
        >
          {{ $t('projects.orders.' + option) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  props: {
    mkt: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const router = useRouter()
    const show = ref(false)
    const store = useStore()

    const options = ref([
      'created_at',
      '-created_at',
      'interest_rate',
      '-interest_rate'
    ])

    const order = computed(() => {
      return store.state.projects.order
    })

    function hide() {
      setTimeout(function () {
        show.value = false
      }, 200)
    }

    const open = () => {
      options.value = props.mkt
        ? ['created_at', '-created_at']
        : ['created_at', '-created_at', 'interest_rate', '-interest_rate']
      show.value = !show.value
    }

    function setOrder(order) {
      router.push({
        query: { order: order }
      })
      store.dispatch('setOrder', order)
    }
    return { show, options, setOrder, order, hide, open }
  }
}
</script>
